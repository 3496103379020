import React from "react"
import { Link, graphql } from "gatsby"
import * as Icon from "react-feather"
import { Helmet } from "react-helmet"
import Layout from "../../components/_App/layout"
import SEO from "../../components/_App/seo"
import NavbarNew from "../../components/NavbarNew"
import Footer from "../../components/_App/Footer"
import PageBanner from "../../components/Common/PageBanner"
import GetInTouch from "../../components/get-in-touch"
import aboutUsOverview from "../../assets/images/brain-with-art.png"
// import mobileAppIconBig from "./mobile-app-service.png"
// import webAppIconBig from "./web-app-service.png"

const AboutUs = () => {
  return (
    <Layout>
      <SEO title="About Tarkashilpa" description="" />

      <NavbarNew />

      <PageBanner pageTitle="About Tarkashilpa" />

      <div className="services-area ptb-80 bg-f7fafd">
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-6 col-md-6 services-details-image d-none d-md-block">
              <img
                src={aboutUsOverview}
                className="animate__animated animate__fadeInUp animate__delay-0.6s"
                alt="About Tarkashilpa"
              />
            </div>
            <div className="col-lg-6 col-md-6 services-list-content">
              <div className="section-title text-left">
                <div className="services-details-image d-block d-md-none">
                  <img
                    src={aboutUsOverview}
                    className="animate__animated animate__fadeInUp animate__delay-0.6s"
                    alt="About Tarkashilpa"
                  />
                </div>
                <p>
                  We are a team of software developers and artists passionate
                  about solving problems using technology. We measure the
                  success of the software by how well it solves the problem.
                  Also, we believe building high-value software is a process
                  that involves continuous iterations of feedback and
                  improvement. Also, we believe excellence is a never-ending
                  process and there is always a better way of solving the
                  problem. That's the reason we cherish the culture of
                  constantly challenging standard methodologies and practices,
                  as we believe this is how innovations are manifested.
                </p>
                <p>
                  The name of our agency serves as a constant reminder to us of
                  our commitment to constantly challenge 'Tarka' (logic) to
                  create a better 'Shilpa' (work of art) every time we build it.
                </p>
              </div>
            </div>
          </div>
          <div className="about-inner-area">
            <div className="row justify-content-center">
              <div className="col-sm-6">
                <div className="about-text">
                  <h3>Vision</h3>
                  <p>
                    Our vision is to deliver custom software that delivers high
                    returns on investment (ROI) to businesses.
                  </p>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="about-text">
                  <h3>Mission</h3>
                  <p>
                    We intend to gain expertise in solving select problems and
                    use this expertise in delivering custom software that
                    provides high returns on investment (ROI) to businesses
                    facing these problems.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="about-stats bg-image">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-4 col-6 col-sm-6">
              <div className="single-funfact">
                <h3>2016</h3>
                <p>Founded</p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-6 col-sm-6">
              <div className="single-funfact">
                <h3>30+</h3>
                <p>Completed projects</p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-6 col-sm-6">
              <div className="single-funfact">
                <h3>20+</h3>
                <p>Happy clients</p>
              </div>
            </div>           
          </div>
        </div>
      </div>

      <GetInTouch />

      <Footer />
    </Layout>
  )
}

export default AboutUs
